<template>
  <div class="w-full px-4 py-12 mb-32 lg:py-12 sm:px-10">
    <div class="font-bold text-gray-type-2">
      Trips report
    </div>
    <div class="items-center mt-6 space-y-4 lg:space-y-0 lg:space-x-4 lg:flex">
      <div class="p-4 bg-white border rounded border-gray-type-7">
        <div class="flex items-center justify-between">
          <div class="inline-flex">
            <span class="text-xs font-medium text-gray-type-2">Start date:</span>
            <datepicker
                v-model="filterData.startDate"
                ref="startDatePicker"
                class="w-20 ml-2 text-xs font-medium border-0 focus:outline-none text-gray-type-8"
                :config="{
                wrap: true,
                altFormat: 'j M Y',
                altInput: true,
                dateFormat: 'Y-m-d',
              }"
            ></datepicker>
          </div>
          <button
              class="ml-8 focus:outline-none"
              @click="$refs.startDatePicker.fp.open()"
          >
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h20v20H0z" />
                <rect
                    stroke="#737D78"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    x="3.125"
                    y="3.125"
                    width="13.75"
                    height="13.75"
                    rx="3"
                />
                <path
                    stroke="#737D78"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.773 1.875v2.5M6.273 1.875v2.5M3.125 6.898h13.75"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="p-4 bg-white border rounded border-gray-type-7">
        <div class="flex items-center justify-between">
          <div class="inline-flex">
            <span class="text-xs font-medium text-gray-type-2">End date:</span>
            <datepicker
                v-model="filterData.endDate"
                ref="endDatePicker"
                class="w-20 ml-2 text-xs font-medium border-0 focus:outline-none text-gray-type-8"
                :config="{
                wrap: true,
                altFormat: 'j M Y',
                altInput: true,
                dateFormat: 'Y-m-d',
              }"
            ></datepicker>
          </div>
          <button
              class="ml-8 focus:outline-none"
              @click="$refs.endDatePicker.fp.open()"
          >
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h20v20H0z" />
                <rect
                    stroke="#737D78"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    x="3.125"
                    y="3.125"
                    width="13.75"
                    height="13.75"
                    rx="3"
                />
                <path
                    stroke="#737D78"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.773 1.875v2.5M6.273 1.875v2.5M3.125 6.898h13.75"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <app-button
          class="w-auto px-10 py-3 font-bold text-center text-gray-type-15 rounded border border-gray-type-15"
          @click="loadSummary(null, null)"
          :loading="loadingSummary">
        Preview Report
      </app-button>
      <div class="p-0 mw-15">
        <input-list-selector label="Route:"
                             @item-selected="updateActiveRoute"
                             :data="availableRoutes"
                             :bind-key="'id'"
                             :bind-label="'description'"
                             :placeholder="'Select route'">
        </input-list-selector>
      </div>

    </div>
    <div class="mt-10">
      <div class="ml-8 font-bold text-gray-type-2">Details</div>
      <template v-if="!filterData.route?.id">
        <app-table :items="reportData"
                   :loading="loadingSummary"
                   :error-loading="errorLoading"
                   :extra-options="tableDataOptions"
                   :fields="reportFields" class="mt-6">
          <template v-slot:first_name="{ item }">
            <div class="text-xs uppercase font-medium text-gray-type-3">
              {{item.first_name }} {{item.last_name}}
            </div>
          </template>

          <template v-slot:total_amount="{ item }">
            <div class="text-xs font-medium text-gray-type-3">
              {{ item.total_amount || (item.total_amount == 0) ? `₦${$filters.money(item.total_amount)}` : 'N/A' }}
            </div>
          </template>
        </app-table>
      </template>

      <template v-else>
        <app-table :items="routeReportData"
                   :loading="loadingSummary"
                   :error-loading="errorLoadingRouteData"
                   :extra-options="tableDataOptions"
                   :fields="routeReportFields" class="mt-6">
          <template v-slot:full_name="{ item }">
            <div class="text-xs capitalize font-medium text-gray-type-3">
              {{item.full_name }}
            </div>
          </template>
          <template v-slot:total_amount="{ item }">
            <div class="text-xs font-medium text-gray-type-3">
              {{ item.total_amount || (item.total_amount == 0) ? `₦${$filters.money(item.total_amount)}` : 'N/A' }}
            </div>
          </template>
          <template v-slot:action="{ item }">
            <a
                @click="loadUserTripDetails(item)"
                class="text-xs capitalize font-medium text-green-type-1"
                target="_blank"
                rel="noopener noreferrer"
            >View Attendance</a>
          </template>
        </app-table>
      </template>
    </div>
    <modal :show="showDetailModal"
           :size="'5xl'"
           @close="closeDetailModal">
      <div class="p-6">
        <div class="flex items-center justify-between">
          <button
              aria-label="Close panel"
              class="transition duration-150 ease-in-out text-gray-type-2"
              @click="closeDetailModal"
          >
            <svg
                class="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto font-bold text-gray-type-2">
            <span class="capitalize">{{selectedStaffRouteData.full_name}} attendance:</span> ({{startDate + ' - ' + endDate}})
          </div>
        </div>
        <div class="mt-2">
          <staff-trip-calendar
              v-if="selectedStaffRouteData"
              :data="filterData"
              :attendance-data="selectedStaffRouteData">
          </staff-trip-calendar>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import AppTable from "@/components/AppTable";
import {mapGetters} from "vuex";
import StaffTripCalendar from "@/views/app/reports/components/StaffTripCalendar";
import Modal from "@/components/Modal";
import InputListSelector from "@/views/app/reports/components/InputListSelector";
import axios from "@/services/axios";

export default {
  name: "UtilityReport",
  components: {
    InputListSelector,
    StaffTripCalendar,
    Modal,
    AppTable
  },
  data() {
    return {
      filterData: {
        startDate: null,
        endDate: null,
        route: null
      },
      selectedStaffRouteData: null,
      availableRoutes: [],
      loadingSummary: false,
      errorLoading: false,
      reportData: [],
      errorLoadingRouteData: false,
      routeReportData: [],
      reportFields: [
        { key: "first_name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "total_amount", label: "Amount" },
        { key: "total_trips", label: "Total trips" },
        { key: "staff_id", label: "Staff ID" },
      ],

      routeReportFields: [
        { key: "full_name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "designation", label: "Designation" },
        { key: "total_amount", label: "Amount" },
        { key: "total_trips", label: "Total trips" },
        { key: "employee_id", label: "Staff ID" },
        { key: "action", label: "Action" },
      ],
      showDetailModal: false,
      tableDataOptions: {
        itemsPerPage: 25
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    startDate() {
      return new Date(this.filterData.startDate).toDateString();
    },
    endDate() {
      return new Date(this.filterData.endDate).toDateString();
    }
  },
  mounted() {
    this.initializeFilterDefaults(this.$route.query.start, this.$route.query.end);
    if(this.filterData.startDate && this.filterData.endDate){
      this.loadSummary( );
    }
    this.fetchAllAvailableRoutes(this.user.id);
  },
  methods: {
    fetchAllAvailableRoutes(corporateId) {
      axios.get(`/corporates/${corporateId}/route`)
          .then((res) => {
            this.availableRoutes = res && res.data ? res.data.map((obj) => {
              obj = Object.assign({}, obj, {description: `${obj.pickup} - ${obj.destination}`});
              return obj;
            }) : [];
          })
          .catch(() => {
          });
    },
    loadRouteReportData(routeId) {
      if(!this.filterData.startDate || !this.filterData.endDate) {
        this.$toast.error('Please ensure a valid date range is selected');
        return;
      }
      this.loadingSummary = true;
      this.errorLoadingRouteData = false;
      axios.request( {
        url: `/corporates-report/${this.user.id}/routes/${routeId}`,
        method: 'POST',
        data: {
          start_date: this.filterData.startDate,
          end_date: this.filterData.endDate
        }
      }).then((res) => {
        this.routeReportData = res && res.data && Array.isArray(res.data) ? res.data : [];
      }).catch((err) => {
        console.error(err);
        this.errorLoadingRouteData = true;
        this.$toast.error('An error occurred while loading report data');
      }).finally(() => {
        this.loadingSummary = false;
      });
    },
    loadSummary(page = null, size = null) {
      if(!this.filterData.startDate || !this.filterData.endDate) {
        this.$toast.error('Please ensure a valid date range is selected');
        return;
      }
      this.loadingSummary = true;
      this.errorLoading = false;
      let url = `corporates-report/${this.user.id}/summary?start_date=${this.filterData.startDate}`
          +`&end_date=${this.filterData.endDate}`;
      if(page && size) {
        url+=`&limit=${size}&page=${page}`;
      }
      axios.get(url)
          .then((res) => {
            this.reportData = res && res.data && Array.isArray(res.data)  ? res.data : []
          })
          .catch((err) => {
            console.error(err);
            this.errorLoading = true;
            this.$toast.error('An error occurred while loading report data');
          })
          .finally(() => {
            this.loadingSummary = false;
          });
    },
    loadUserTripDetails(data){
      if(data.attendance && data.attendance.length) {
        this.selectedStaffRouteData = data;
        this.showDetailModal = true;
      } else {
        this.$toast.error('No attendance information available for selected user');
      }
    },
    closeDetailModal() {
      this.selectedStaffRouteData = null;
      this.showDetailModal = false;
    },
    initializeFilterDefaults(startDate, endDate) {
      this.filterData = {...this.filterData, startDate, endDate };
    },
    updateActiveRoute(value) {
      if(value.id) {
        this.filterData.route = value;
        this.loadRouteReportData(this.filterData.route.id);
      } else {
        this.filterData.route = null;
        this.loadSummary();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep td.whitespace-nowrap {
    &:last-of-type {
      @apply pr-5
    }
  }
  .mw-15 {
    min-width: 15rem;
  }
</style>